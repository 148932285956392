<template>
  <div class="appendix">
    <div v-html="content" class="content" />
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="isDisabled">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import httpApi from "../../utils/httpApi";

const content = `
Direct Market Access and Algorithmic Server Agreement

Beevest Financial Trading Limited

And

______________________


THIS AGREEMENT is made and entered into as of _______	by and between

1.Beevest Financial Trading Limited of Units 1013 & 1015, 10/F, Leighton Centre, 77 Leighton Road, Causeway Bay, Hong Kong（BVT）

2. of	______ (the “User”).

WHEREAS

A.BVT has implemented a system which will allow the User to access BVT’s market trading system, and/or algorithmic trading tools, to submit Orders relating to the sale and/or purchase of financial instruments, via BVT, to the relevant exchange (“the System”).

B.BVT wishes to grant the User and the User wishes to be granted, access to the System, on the terms and conditions of this Agreement and any Trading Agreements.

NOW IT IS HEREBY AGREED AS FOLLOWS:

1.Definitions

1.1.All headings in this Agreement are inserted for convenience and do not affect the interpretation of this Agreement.

1.2.In this Agreement, the following words and expressions shall have the meanings set out hereunder unless the context otherwise requires: algorithmic trading tools means the algorithmic trading tools set forth in specifications provided by BVT to the User and as agreed by the parties from time to time.

Applicable Laws includes, but is not limited to, any rules, regulations, orders, decisions, procedures and other requirements whatsoever whether having legal force or otherwise of any applicable regulatory body, exchange, clearing house, governmental or other authority to which a party or the execution and/or clearing of any transactions under the Trading Agreements is subject;

Confidential Information means any proprietary information, technical data, or know-how, including, but not limited to, that which relates to specifications, research, products, services, orders, strategies, forecasts, forecast assumptions, methodologies, models, customers, software, developments, inventions, processes, systems, designs, hardware configuration information, marketing or financial data, disclosed by one party (“the Disclosing Party”) to the other (“the Receiving Party”);

Loss means any and all loss, damage, costs, charges and/or expenses of whatsoever nature and howsoever arising, including, but not limited to, legal fees on a full indemnity basis, cost of funding and loss or cost incurred as a result of the terminating, liquidating or re-establishing of any hedge or related trading position;

Receipt Acknowledgement means an electronic message automatically forwarded to the User by BVT acknowledging receipt by BVT of an Order;

Securities means the securities listed and traded on the relevant exchange which the User is accessing through the System and includes, but is not limited to, equity, debt, warrants and options;

Supplier means a third party broker or vendor that provides any part of the System to BVT (and not the User). For the avoidance of doubt, the User does not have a contractual relationship with any Supplier.

Trading Agreements means any and all documentation between the User and BVT and includes without limitation customer agreements, securities lending agreements and custody agreements as agreed or amended from time to time; and

Transaction means any transaction in any Securities as may be permitted by BVT.

2.General Conditions

2.1.BVT hereby grants the User a limited non-exclusive, non-transferable right to access BVT's System to submit orders to a relevant exchange (the "Orders"), through either an BVT proprietary or vendor provided electronic front-end trading interface and certain telecommunications systems, using BVT's connectivity to the relevant exchanges, tools, models and services, subject to the terms and conditions of this Agreement.

2.2.The User’s use of the System is an automatic and irrevocable confirmation by the User that all of the User’s Orders are duly authorized and in accordance with the Applicable Laws. BVT is not bound to enquire into the placement of any Order or its terms.


2.3.BVT may, at any time without notice to the User; provided that BVT shall use its best endeavors to provide such notice to the User if practicable in the circumstances::-

2.3.1.limit, suspend or terminate the System ; and/or

2.3.2.revoke or suspend the User's access to and use of the System .

2.4.The User’s relationship with BVT is limited to the access to and use by the User of the System, and the User shall comply with the Applicable Laws when accessing and using the System.

2.5.BVT shall use best endeavors to ensure that the integrity and security of the System is preserved and maintained. The User shall not access or use the System in a manner that will impact the integrity and security of the System.

2.6.The User confirms that, all times during the term of this Agreement,:

2.6.1It has appropriate arrangements in place to ensure that its users are proficient and competent in using the System;

2.6.2It understands and has the ability to comply with applicable regulatory requirements; and

2.6.3It has in place adequate arrangements to monitor the orders entered through the System.

3Choice and use of the System

3.1The User acknowledges that the System has not been developed for the User’s individual needs and has been selected and is used by it at its risk and that BVT, unless it has agreed otherwise in writing, shall bear no responsibility for the User’s choice or use of the System.

3.2Unless BVT and the User have agreed otherwise in writing, the User is responsible for providing and maintaining any relevant hardware and software and for making all necessary arrangements with any Supplier in relation to obtaining access to and using the System.  The User is responsible for complying with all requirements imposed  in relation to its access to and use of the System, including any applicable security procedures, and any applicable agreement entered into by the User in relation to the supply of any part of the System to it.

3.3The User acknowledges and agrees that its access to and use of the System is at its sole risk.

3.4The User acknowledges and agrees that the System and/or parts of it may be provided and/or maintained by Suppliers of BVT.

4Term and Termination

4.1This Agreement shall commence on the date first mentioned above and shall continue in force until terminated by either party upon thirty (30) days' prior written notice to the other party, except that BVT reserves the right to suspend the User's use of the System or immediately terminate this Agreement at any time without any liability to the User.

4.2For the avoidance of doubt, termination of this Agreement shall not affect the rights and liabilities of the parties that have accrued prior to such termination.

4.3Clauses 11, 12, 13 and 15 shall survive termination of this Agreement.

5 Orders

5.1All Orders placed through the System are at the User's risk.

5.2BVT may pass Orders to any of its affiliates for execution and settlement and this Agreement shall apply to all transactions entered into by the User via the System with any such affiliate, provided that BVT shall be fully responsible for all such affiliates.

5.3The User acknowledges that the placement of an Order through the System does not guarantee receipt, acceptance or execution of the Order. A Receipt Acknowledgement shall be sent electronically to the User for each Order submitted by the User through the System. The System shall only be deemed to have received any Order when a Receipt Acknowledgement has been sent in respect of that Order.

5.4The User hereby acknowledges and agrees that the System may reject any Order without having to provide a reason. Such rejection shall be notified to the User automatically provided that failure to notify shall not prejudice the effectiveness of any rejection.

5.5It is the User's responsibility to ensure that an Order has been duly transmitted and to check if a Receipt Acknowledgement has been sent to the User.

5.6The User acknowledges that, for the purposes of determining whether to allow an Order to be routed through to the relevant exchange for execution, BVT may conduct a systemic review of the Order. Accordingly, any such Order that is cleared to be passed through to the relevant exchange for execution may therefore be delayed.

5.7The User may give BVT an order to cancel or vary a previous Order by submitting a message via FIX to BVT’s system, or by contacting the System support, provided that, BVT shall not be liable for any Loss to the User if the Order is not cancelled or varied notwithstanding BVT's receipt of such an Order and a request from the User to cancel or vary that Order.

6 Commission

The User shall promptly pay all of BVT’s fees and/or other charges at such rates and in such manner as shall be mutually agreed from time to time.

7Trading Restrictions

7.1The User's Orders will be subject to trading restrictions that BVT may establish, revise and communicate to the User in advance from time to time.

7.2Whenever the User transacts in:

7.2.1Australian securities, Annexure 1 shall strictly apply;

7.2.2Japanese securities, Annexure 2 shall strictly apply;

7.2.3Korean securities, Annexure 3 shall strictly apply;

7.2.4Hong Kong securities, Annexure 4 shall strictly apply;

7.2.5Malaysian securities, Annexure 5 shall strictly apply;

7.2.6Singaporean securities, Annexure 6 shall strictly apply;

7.2.7Taiwanese securities, Annexure 7 shall strictly apply; and

7.2.8Thai Securities, Annexure 8 shall strictly apply.

7.3For the avoidance of doubt, the User agrees that any Order that amounts to a short sale in any jurisdiction shall be clearly identified as such to BVT at the time of placing such Order.

8Authorized Persons

8.1The User shall limit access to the System to those authorized persons for whom such access is necessary to perform their duties as an officer or employee of the User (an “Authorized Person”). The User shall be responsible for the actions of any persons authorized or unauthorized, who gain access to the System through the User.

8.2BVT shall not be liable for inappropriate security, if any, of any network services through which the User may access the System, or for any unauthorized use of the System by the User or any of the User’s directors, officers, employees, contractors or agents.

9Representations, Warranties and Undertakings

9.1The User hereby represents and warrants to BVT that:-

9.1.1this Agreement is a legal, valid and binding obligation of the User, enforceable against the User in accordance with its terms;

9.1.2it has all authorizations, consents, licenses or approvals (whether under the Applicable Laws or otherwise) required to accept and agree to this Agreement, to access and use the System, and to give BVT Orders;

9.1.3any Orders placed are solely and exclusively based on its own judgment and after its own independent appraisal and investigation into the risks associated with such Orders or dealings;

9.1.4any person(s) empowered to act on the User's behalf has been duly authorized;

9.1.5each of the Authorized Persons is familiar with, understands, and will keep themselves updated on the Applicable Laws;

9.1.6it has read and familiarized itself, as appropriate, with the rules of all relevant exchanges; and

9.1.7it is a sophisticated market participant and is knowledgeable about the System and appreciates and accepts all risks related with trading via such order routing systems;

9.1.8it has appropriate arrangements in place to ensure that its Authorized Persons are proficient and competent in using the System;

9.1.9it understands and has the ability to comply with applicable regulatory requirements in Hong Kong relating to electronic trading; and

9.1.10it has in place adequate arrangements to monitor the orders entered by it into the System.

9.2BVT hereby represents and warrants to the User that it has all rights, authority and licenses to provide the System to the User, as set forth herein.

9.3The above representations, warranties, agreement and undertakings shall be deemed repeated whenever the User transmits Orders using the System.

10Notice by the User

The User shall notify BVT immediately upon its becoming aware of any of the following:-

10.1any unauthorized use of the System through the User’s access to the System;

10.2any failure by the User to receive a Receipt Acknowledgement, statement, confirmation, advice and/or other appropriate response in the ordinary course of business that any Order has been received and/or executed; and/or

if the User receives a Receipt Acknowledgement, statement, confirmation, advice and/or response relating to an Order which the User did not place.

11Confidentiality

11.1Both parties undertake not to disclose to any person or persons any Confidential Information and/or documents of the other party that it may acquire in the course of its access and use of the System; and provided, however, that such confidential information may be disclosed:

11.1.1to the extent required by any Applicable Law;

11.1.2to the extent reasonably required for the performance of a party’s obligations under this Agreement, provided that all the parties so disclosed to are under confidentiality obligations;

11.1.3pursuant to a subpoena or order of a court or regulatory, self-regulatory or legislative body of competent jurisdiction;

11.1.4in connection with any regulatory report, audit or inquiry; or

11.1.5where requested by a regulator with jurisdiction over the Receiving Party.

11.2In the event that either party or their respective directors, officers, employees, consultants or agents are requested or required by legal process to disclose any of the Confidential Information of the other party, the party required to make such disclosure shall, to the extent permitted by law, give prompt notice so that the other party may seek a protective order or other appropriate relief. In the event that such protective order is not obtained, the party required to make such disclosure shall disclose only that portion of the Confidential Information which its counsel advises that it is legally required to disclose.

11.3The restrictions set out in Clause 11.1 on both parties shall not apply to information or knowledge which:-

11.3.1is publicly available or becomes publicly available at the time of disclosure through no act or omission of the Receiving Party;

11.3.2was in the possession of, or demonstrably known by, the Receiving Party prior to its receipt from the Disclosing Party;

11.3.3is approved for release by the Disclosing Party in writing;

11.3.4is independently developed by the Receiving Party outside of this Agreement without reliance on or use of the Confidential Information; or

11.3.5was rightfully obtained by the Receiving Party from third parties without an obligation of confidentiality.

11.4Each party agrees to maintain the confidentiality of the Confidential Information using procedures no less rigorous than those used to protect and preserve the confidentiality of its own similar proprietary information, and shall not, directly or indirectly:

11.4.1transfer or disclose any Confidential Information to any third party;



11.4.2use any Confidential Information other than as contemplated under this Agreement; or

11.4.3take any other action with respect to Confidential Information inconsistent with the confidential and proprietary nature of such information.

12Intellectual Property

12.1The User accepts and acknowledges the fact that all intellectual property rights (whether by way of copyright or otherwise) in the System vest solely in and shall remain the exclusive property of BVT or the relevant Supplier. The User therefore agrees not to do anything that will violate or infringe BVT’s or the relevant Supplier's intellectual property rights and shall take all necessary measures to preserve and protect these rights.

12.2BVT represents and acknowledges that, at the time of entering into this agreement, it has all intellectual property rights necessary to provide the System; and BVT will use best efforts to maintain all intellectual property rights with respect to the System during the term of this agreement.

13Warranty Disclaimer

The System is provided on an “as is”, “as available” basis. BVT makes no warranty, guarantee or representation of any kind, express or implied in relation to the System (and any results to be obtained from its use), and to the extent permitted by the Applicable Laws, expressly disclaims all statutory or implied warranties, in relation to the System (and any results to be obtained from its use), satisfactory quality, quality or fitness for a particular purpose or use, accuracy, completeness, warranties arising from course of performance, course of dealing and usage of trade or their equivalents under the laws of any jurisdiction, and warranties that its functions will meet the User's requirements, that its operation will be timely, accessible, secure, complete, reliable, uninterrupted or error-free, or that it will be free of viruses or harmful components.

14Regulatory Supervision

BVT shall have no liability, and shall not be responsible, for any prejudice, loss or inconvenience that the User may suffer as a result of any action by any regulatory body in the exercise of its regulatory or supervisory functions over BVT. The User shall permit BVT and/or any regulatory body to have access to such terminals, computer systems or equipment in respect of the System as BVT and/or the regulatory body may request and the User shall co-operate in answering any of their queries and render all reasonable assistance to BVT and/or any regulatory body in relation to any aspect of the System.

15General Indemnity

The User agrees to indemnify BVT and each of its respective employees and agents (“Indemnified Persons”) against any and all Loss arising against or incurred by the Indemnified Persons as a result of, in connection with or arising out of the User’s use of the System, any claims by Suppliers against BVT in respect of the User’s  use of the System, any failure by the User to comply with any of the terms and conditions set out in this Agreement, Orders or transactions placed by the User and/or any activity or inactivity of the User in connection with this Agreement except for any Loss caused by BVT’s gross negligence, willful misconduct and/or fraud.

16General Exclusion

16.1In addition and without prejudice to any other right or remedy of BVT (at law or otherwise), so long as BVT acts in good faith, BVT shall not be liable to the User in any respect for any Loss suffered by the User arising out of use of the System. BVT shall only be liable to the User if BVT has been grossly negligent, fraudulent or in willful default.  Without prejudice to the generality of the foregoing, BVT shall not be liable to the User for any indirect or consequential Loss, any anticipated profits or punitive damages.

16.2In the event of any failure, interruption or delay in the performance of BVT’s obligations hereunder as a result of any act, event or circumstance not reasonably within the control of BVT from time to time, including but not limited to:

16.2.1industrial disputes, acts or regulations of any government or governmental or supranational bodies, agencies or authorities, or of any exchange or any regulatory body whether in Hong Kong or elsewhere;

16.2.2service malfunctions, delay, suspension, interference, or operational difficulties encountered by third party service providers of electrical communications and telecommunication facilities wherever located;

16.2.3breakdown, failure, delay, interference or malfunction (in each case, whether total or partial, temporary or permanent) of or under any communications, telecommunications or computer services or electronic equipment;

16.2.4defaults by third parties in the performance of their obligations necessary to have been performed for the completion of any transactions;

16.2.5suspension or restriction of trading on any exchange or other market, or the failure of any relevant exchange, clearing house and/or broker for any reason to perform its obligations; and

16.2.6orders of courts, fire, war, natural disaster, terrorist acts, riots or civil commotion,

neither BVT nor any of its affiliates shall be liable or have any responsibility of any kind for any Loss thereby suffered or incurred by the User or its client/s.

17General

17.1This Agreement may be signed in one or more counterparts.

17.2No amendment or modification of any provision of this Agreement shall be effective unless in writing and signed by both parties.  No waiver under any provision of this Agreement shall be effective unless in writing and signed by the waiving party. The waiver of or delay by either party in exercising its rights resulting from a breach of or a default under any provision of this Agreement, shall not be construed as a waiver of any subsequent breach of the same or any other provision of the Agreement.

17.3Neither party may assign any right of, interest in, or obligation under this Agreement to any third party without the prior written consent of the other party.

17.4This Agreement supplements and/or amends and is to be read together with the Trading Agreements. In the event of any inconsistency between this Agreement and the Trading Agreements, the terms of this Agreement shall prevail.

17.5A person who is not a party to this Agreement has no right to enforce any term of this Agreement.

17.6Any failure to exercise or any delay in exercising any right, power, privilege or remedy under this Agreement by any party shall not impair or operate as a waiver thereof in whole or in part.

17.7This Agreement and the relationship between the User and BVT shall be governed by, and construed in accordance with, the laws of the Hong Kong Special Administrative Region (the “HKSAR”). The parties hereby irrevocably submit to the exclusive jurisdiction of the courts of the HKSAR.

17.8If any provision of this Agreement is or should become inconsistent with any present or future law, rule or regulation of any governmental or regulatory body having jurisdiction over the subject matter of this Agreement, such provision will be deemed to be rescinded or modified in accordance with any such law, rule or regulation. In all other respects, this Agreement will continue and remain in full force and effect.

17.9Any notice or communication to be made under these terms and conditions of this Agreement shall be deemed to have been served or delivered if sent:

17.9.1by facsimile, at the time of despatch;

17.9.2by hand, at the time left at the relevant address;

17.9.3by post, forty-eight hours after being put in the post with pre-paid postage and being properly addressed; or

17.9.4by e-mail, at the time of transmission if the e-mail is sent by BVT and, at the time the e-mail is actually received by BVT if the e-mail is sent by the User.

17.10This Agreement is entered into in consideration of the Parties incurring obligations and giving rights under this Agreement and for other valuable consideration.

17.11This Agreement and its Annexures constitute the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior agreements whether oral or written, with respect thereto.

17.12In the event of any difference or conflict between this Agreement and BVT’s Client Agreement (including all the terms of business, appendices and schedules, the “Client Agreement”), in relation to the use of the System and orders placed into the System, this Agreement shall prevail.


IN WITNESS WHEREOF this Agreement has been executed on the day and year first above written. For And On Behalf Of	For And On Behalf Of

Beevest Financial Trading Limited


Name:	Name:
Title: 	Title:

ANNEXURE 1

CONDITIONS RELATING TO ORDERS INVOLVING AUSTRALIAN SECURITIES

The User confirms as follows:

1.it holds all necessary approvals, authorizations and licenses required for it to use the System to submit instructions directly through to a market conducted by the Australian Securities Exchange (“ASX”) and/or Chi-X Australia Limited (“Chi-X”) and otherwise undertake the Transactions contemplated under this Agreement;

2.it understands the operation of the System and the dealing rules, procedures and practices of the ASX and Chi-X;

3.it will comply with all short selling rules relating to trading on the ASX and other Australian exchanges;

4.it will ensure that each transaction conducted by it using the System will be lawful and in particular, will ensure that its use of the System will not interfere with:

(a)the efficiency and integrity of all Australia exchanges; or

(b)the proper functioning of the trading system provided for the trading of securities on all Australian exchanges;

5.it is aware of the disclosure obligation under ASIC’s Class Orders in relation to short selling to inform BVT of the type of sale it is requesting when selling a section 1020B product (as defined in the Corporations Act 2001) and it understands that it needs to provide this information to BVT at the time of requesting the sale (“the Disclosure Obligation”);


7.it will not place orders for “exempt covered short sales” (as defined by ASIC) via the System or with BVT at all;

8.if it places sale orders via the telephone, it understands that it must specify the type of sale order it is placing as either a “Sell long” or “Sell short”;

9.if it places an order using the FIX protocol, it understands how it can use specific values in FIX protocol compliant trading systems in order to fulfil its Disclosure Obligation; and

10.if it places an order using an IRESS trading system, it understands how to use the mandatory fields provided in the IRESS trading system in order to fulfil its Disclosure Obligation.

ANNEXURE 2 CONDITIONS RELATING TO ORDERS INVOLVING JAPANESE SECURITIES

1.The User’s Orders shall comply with the Applicable Laws including, but not limited to, the following prohibitions:

“Unjust Trade Activities” pursuant to Article 157 of the Financial Instruments and Exchange Law of Japan and other related laws and rules;

“Manipulation Transactions” pursuant to Article 159 of the Financial Instruments and Exchange Law of Japan and other related laws and rules;

“Short Selling Restrictions” pursuant to Article 162 of the Financial Instruments and Exchange Law of Japan and other related laws and rules; and

“Insider Trading” pursuant to Article 166 of the Financial Instruments and Exchange Law of Japan and other related laws, regulations and rules.

2.The User acknowledges and agrees that its Orders must not constitute a breach of the prohibitions listed in paragraph 1 of this Annexure.

3.Further to the above, User agrees that any Order which amounts to a short sale (pursuant to Article 26-3 of The Enforcement Ordinance of the Financial Instruments and Exchange Law of Japan) shall be clearly identified as such to BVT at the time of placing such Order.

4.Only market or limit Orders for cash transactions in Japanese equities will be accepted.

5.Orders in relation to securities listed on the Nagoya, Fukuoka or Sapporo stock exchanges are to be executed by another broker who is a member of such exchange. However Algorithmic Orders in relation to securities listed on such exchanges will not be accepted for execution.

6.Market Maker issues in over-the-counter trading securities transactions by short sell or margin transactions will not be accepted for execution.

7.Orders in certain stocks may not be accepted or executed in circumstances where BVT or an affiliate or an agent is restricted by regulations or internal rules from accepting or executing such orders.

ANNEXURE 3

CONDITIONS RELATING TO ORDERS INVOLVING KOREAN SECURITIES ON THE KOREA EXCHANGE (KRX)

1.The User represents and warrants that it fully understands all applicable Korean laws, rules, regulations, customs and usage (“Applicable Rules”) relating to market misconduct, and agrees to observe the Applicable Rules. Without in any way limiting the generality of the foregoing, the User represents and warrants that it fully understands the relevant provisions, as they may be amended or restated from time to time, of the Securities and Exchange Act (Korea) and its Presidential Decree.

2.The User represents and warrants that it will ensure that all its offices and employees given access to the System fully understand and agree to comply with the provisions of the Applicable Rules.

3.The User acknowledges and agrees that BVT will fully co-operate with the Financial Supervisory Service (“FSS”) and Korea Exchange in Korea in respect of any inquiry or investigation into your trading activity conducted by the System. The User acknowledges and agrees that it will provide the User’s trading records and other related information upon request by the regulators.

ANNEXURE 4

CONDITIONS RELATING TO ORDERS INVOLVING HONG KONG SECURITIES

1.The User agrees it shall notify BVT, at the time of placing an Order, whether or not such Order amounts to Short Selling (as defined in the Short Selling Regulations set out in the Eleventh Schedule to The Rules of the Stock Exchange of Hong Kong Limited).

2.The User agrees that, in connection with any Order that amounts to Short Selling, it shall provide confirmation to BVT that such Order amounts to covered Short Selling.

For the purposes of this Annexure, “covered Short Selling” takes place where, at the time of placing an Order, the User does not own the Securities but has a presently exercisable and unconditional right to vest the Securities in the purchaser of them by virtue of having:-

(a)under a securities borrowing or lending agreement, borrowed the securities or obtained a “hold notice” from a stocklender to borrow the amount of securities being sold; or

(b)owned presently exercisable options, subscription rights, warrants, convertibles, exchangeable securities (such as ADRs and TraHK units) or similar rights to acquire the underlying Securities.

In relation to (b) above, where the User issued unconditional instructions to obtain the underlying Securities  prior to placing the Order, the sale is a long sale rather than a covered Short Sale (unless at the time of the Order, the User’s right to deliver those Securities to the purchaser derives from a stock borrowing).

3.The User acknowledges that BVT shall implement a limit-checking engine within the System that shall only accept for execution those Orders that are within certain specified parameters set by BVT.

4.For the purpose of this Annexure, Applicable Laws shall include, but are not limited to, the following:

(a)The Rules of The Stock Exchange of Hong Kong Limited; and

(b)The Securities and Futures Ordinance (Cap. 571)


ANNEXURE 5

CONDITIONS RELATING TO ORDERS INVOLVING MALAYSIAN SECURITIES

The User’s Orders shall comply with:

the Electronic Client Order-Routing System-2 Code;

the Rules of Malaysia Securities Exchange Berhad;

Malaysian Central Depository rules;

The Member Company Information Technology Security Code; and

other relevant rules, directives, guidelines and/or circulates as may be prescribed by the Bursa Malaysia Securities from time to time.

ANNEXURE 6

CONDITIONS RELATING TO ORDERS INVOLVING SINGAPOREAN SECURITIES

1.The User represents and warrants that it and all of its officers and employees fully understand the market and the financial instruments being traded on the Singapore Exchange and will familiarize themselves with and comply with all guidelines posted on the Singapore Exchange’s website at www.sgx.com (as may be amended from time to time).

2.The User represents and warrants that it fully understands all applicable Singaporean laws, rules, regulations, customs and usage (“Applicable Rules”) relating to market misconduct, and agrees to observe the Applicable Rules. Without in any way limiting the generality of the foregoing, the User represents and warrants that it fully understands the relevant provisions, as they may be amended or restated from time to time, of:

(a)Part XII of the Securities and Futures Act (Singapore) relating to prohibited conduct and trading practices including false trading and market rigging, securities market manipulation, false or misleading statements, fraudulent inducement, employment of manipulative and deceptive devices, dissemination of information on illegal transactions and insider dealing; and

(b)Rule 13 of the SGX Rules on Trading Practices and Conduct.

ANNEXURE 7

CONDITIONS RELATING TO ORDERS INVOLVING TAIWAN SECURITIES

This Annexure is made in connection with the User’s order to buy or sell any securities or financial instruments traded on the Taiwan Stock Exchange (“TSE”) or the GreTai Securities Markets via the System (“Taiwan Securities”).

The User warrants and undertakes that it is familiar with and shall entirely comply with all applicable laws/regulations in the jurisdiction of Taiwan, Republic of China (“ROC”), including but not limited to the ROC Securities and Exchange Law, Regulations Governing Investment in Securities by Overseas Chinese and Foreign Nationals and others which are prevailing (“Taiwan Law”), and fully understands the following key points:


(i)the User shall ensure it applies to the Taiwan Securities Exchange Corporation (“TSEC”) for registration in accordance with TSEC operating rules and bylaws by submitting the relevant documents required;

(ii)the User recognizes that the limits on investment in Taiwan Securities by foreign investors shall be set by competent authorities (e.g. Securities and Futures Bureau of Financial Supervisory Commission);

(iii)when applying for exchange settlement of funds in connection with an investment in Taiwan Securities, the User shall act in accordance with the applicable foreign exchange regulations and rules, annexing the approval letter of the TSEC;

(iv)the User may apply for exchange settlement of investment capital, earnings on investments, and proceeds from the sale of borrowed securities; provided, however, that exchange settlement is only permitted for those capital gains and stock dividends that constitute realized gains;

(v)the User shall appoint a local agent or representative to undertake matters such as opening accounts for trading in Taiwan Securities; applying to exchange, convert into, or subscribe to domestic corporate bonds; exercising rights in purchased securities; applying for exchange settlement; and paying taxes;

(vi)the User shall designate a bank, approved by the ROC Ministry of Finance to offer custodial services, to act as its custodian institution and to handle related matters, such as custodianship of funds and certificates related to securities investments, confirmation of trades, transaction settlement, and reporting of relevant information;

(vii)the User shall ensure that it has an open New Taiwan Dollar account in ROC that shall only be for the purpose of settling transactions;

(viii)when applying to a securities firm for opening of an account for securities trading, the User shall ensure it submits an approval letter issued by the TSEC;

(ix)when consigning trading of Taiwan Securities to a domestic securities firm, the User shall provide a record of the consignment;

(x)the User understands that under Taiwan Law, an investor who fails to settle transactions in Taiwan Securities may be suspended from trading for three (3) years. Therefore, it is critical that the User shall strictly abide by  the following provisions when conducting investment in Taiwan Securities:


(a)no margin trading by foreign investors is allowed;

(b)no short selling by foreign investors is allowed, it is the User’s responsibility to carefully check its holdings in its custody account before placing any sell orders;

(c)no extending of loan or provision of guarantee is allowed in respect of any securities or financial instruments traded on the TSE and GreTai (OTC);

(d)it is not allowed to entrust custody of securities to any juridical person or individual other than a custodian institution or centralized securities depository which is approved by competent authorities;

(e)no engaging in Swap or Access trades.

(xi)In the event that the User violates, recklessly or intentionally, any of the prohibitions and restrictions set forth above, the User shall be unconditionally liable for the entire consequences and indemnify and hold you harmless from and against any claim, action, cost, expense, fee, damage or loss incurred by BVT in connection with the violation.

(xii)The User understands that it is a requirement of Taiwan Law that any order to buy and sell Taiwan Securities shall be handled by a registered dealer of a registered securities firm in Taiwan. Hence, each of the User’s orders via the System to buy or sell any Taiwan Securities, before being entered into the exchange system for execution, shall be first approved by a registered dealer of a Taiwan broker (“the Taiwan Broker”). The User agrees and acknowledges that the registered dealer of the Taiwan Broker is entitled to, from time to time, perform any check/clarification he or she deems necessary before approving the User’s orders and may reject any of the User’s orders via the System at his or her own sole discretion; provided, that nothing herein shall obligate the Taiwan Broker to perform such check or clarification.

(xiii)For any order the User submits with the System to the TSE, the User acknowledges and agrees that BVT will not and is not obliged to check with banks, other financial institutions or other agents of the User with which the User holds cash to ensure the User has sufficient funds to pay BVT to enable BVT to discharge any liability incurred or to be incurred by BVT in connection with transactions effected or to be effected for the User in relation to such orders submitted by the User.

(xiv)The User hereby agrees that in order to enhance the efficiency of execution, the Taiwan Broker and BVT shall have no obligation to perform the position availability checking for any of the User’s order routing through the System and it is the User’s sole responsibility to assure the position availability when placing orders via the System.

(xv)The User agrees and acknowledges that BVT’s policy prohibits the firm from taking on clients’ trading errors or losses. If any error occurs to the User’s order to buy or sell any Taiwan Securities via the System and such error is attributable to the User, its agent or employees, the User shall be solely responsible for the error and shall, as required by Taiwan Law, instruct the User’s custodian bank to submit a written acknowledgement letter to the Taiwan Broker to acknowledge such error no later than 3:00 p.m. T+1 day (Taiwan time) so that the Taiwan Broker may proceed with any regulatory reporting. The User also agrees to reimburse BVT for any financial losses arising from any errors attributable to the User’s fault.

(xvi)The User agrees and acknowledges that based on Taiwan Law, trading of certain categories of securities, such as Alarmed Stock, listed warrants or Tiger stock, (collectively called “Pre-Settlement Stock”) may require partially or fully pre-settlement before the order is entered into the exchange system. In order not to cause any unnecessary delay to the execution of other securities which do not require pre-settlement, the User agrees and acknowledges that it shall not trade any Pre-Settlement Stock via the System and the System will automatically reject the User’s order of trading any Pre-Settlement Stock.

(xvii)The User shall fully comply with all Taiwan Law, and shall not do any act or suffer any omission which would cause BVT, the Taiwan Broker or any employees of the Taiwan Broker or BVT to be in violation of same and agrees that, notwithstanding anything to the contrary contained elsewhere herein, neither BVT or the Taiwan Broker shall be obliged to carry out any action contemplated hereunder to the extent that BVT or the Taiwan Broker determines, in its sole absolute discretion, that such would violate, or otherwise exceed any restrictions or limitations set out in Taiwan Law or any other law, regulation or governmental directive or infringe the legal or contractual rights of any other investor; provided, that nothing herein shall obligate BVT or the Taiwan Broker to monitor, supervise or otherwise act to assure that the actions of the User, the User’s agent, employees or others acting, or purpose to act, on the User’s behalf comply with Taiwan Law.

ANNEXURE 8

CONDITIONS RELATING TO ORDERS INVOLVING THAI SECURITIES

1.The relevant exchange shall mean the Stock Exchange of Thailand (“SET”).

2.The User acknowledges and agrees that BVT shall be entitled to provide to the SET (or any other regulator in Thailand) the entity name of the User and any Order information as requested by the SET, upon receipt from the SET of such a request with respect to any Order.

3.The User further acknowledges and agrees that, where the User is placing Orders on behalf of any third party, and BVT receives a request from the SET (or any other regulator in Thailand) to provide the entity name of such third party, the User shall provide such entity name to BVT within 3 Bangkok business days of receipt by the User of such notification from BVT.

4.The User agrees that it shall comply with all relevant Thailand laws and regulations, in particular the rules and regulations of the SET when placing any Order through the System.

`.replace(/\r?\n/g, "<br />");
export default {
  name: "baal",
  components: {
    [Button.name]: Button
  },
  data() {
    return {
      content
    };
  },
  methods: {
    async before() {
      const data = JSON.parse(JSON.stringify(this.submitForm));
      const res = await this.$api.postAgreementState({
        ...data,
        dma_asa: "是"
      });
      if (res) {
        this.$router.back();
      }
    }
  },
  setup() {
    const isDisabled = ref(true);
    const submitForm = ref({});
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "协议详情");
      store.commit("setStepsShow", false);

      const result = await httpApi.getAgreementState();
      const { data } = result;
      isDisabled.value = data.dma_asa === "是";
      submitForm.value = data;
      httpApi.formLog(submitForm.value, isDisabled);
    });
    return {
      isDisabled,
      submitForm
    };
  }
};
</script>

<style lang="less">
.appendix {
  margin-top: @space-2;
  text-align: left;

  .content {
    padding: @space-2;
    background: @contentColor;
    font-size: @font-size-2;
  }
  .btn {
    width: 80%;
    margin: @space-0 auto;
    margin-bottom: 0;
  }
}
</style>
